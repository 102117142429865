import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

function About() {
  const navigate = useNavigate();

  return (
    <motion.div
      key="projects"
      className="h-full overflow-auto pb-12-safe"
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      transition={{
        duration: 0.5,
        ease: [0.85, 0, 0.15, 1],
      }}
    >
      <div className="font-serif text-black text-4xl sm:text-xl md:text-xl mx-6 mb-6">
        Über Mich
      </div>
      <div className="overflow-auto pb-32 mx-6 text-justify flex flex-col justify-between">
        <p>
          <img
            src="Profilbild.jpg"
            alt="Bild"
            className="w-40 rounded-full mr-4 mb-4 float-left"
          />
          Hey, ich bin Tillian! 🤙
          <br />
          <br />
          Bereits in jungen Jahren konnte ich meine Leidenschaft für das
          Programmieren und Design entdecken. Mittlerweile biete ich neben
          meinem dualen Studium als Wirtschaftsinformatiker (B.Sc) vom{" "}
          <b>Print- und Digitalmediendesign</b> über <b>Webentwicklung</b> bis
          hin zur <b>Appentwicklung</b> alles aus einer Hand.
          <br />
          <br />
          Ich verbinde kreatives Design mit technischem Know-how, um
          einzigartige Lösungen zu schaffen, die Ihre Anforderungen perfekt
          erfüllen. Wenn Sie auf der Suche nach einem engagierten Profi sind,
          der Ihre Ideen in die Realität umsetzen kann, dann bin ich Ihr
          Ansprechpartner. Lassen Sie uns gemeinsam Ihre Visionen verwirklichen!
        </p>
        <h2 className="mt-8 font-serif text-xl mb-2">Werdegang</h2>
        <div className="xl:hidden">
          <div className="mb-4">
            <p className="font-bold text-gray-500 text-sm">Juli 2022</p>
            <p className="col-span-2">Abitur</p>
          </div>

          <div className="mb-4">
            <p className="font-bold text-gray-500 text-sm">
              September 2022 ― Februar 2024
            </p>
            <p className="col-span-2">
              Fachinformatiker für Anwendungsentwicklung
              <br />
              <span className="text-gray-600 italic">
                Verkürzte Ausbildungsdauer
              </span>
            </p>
          </div>

          <div className="mb-4">
            <p className="font-bold text-gray-500 text-sm">
              September 2022 ― Februar 2024
            </p>
            <p className="col-span-2">Bachelorstudium Wirtschaftsinformatik</p>
          </div>
          <div className="mb-4">
            <p className="font-bold text-gray-500 text-sm">
              April 2024 ― Heute
            </p>
            <p className="col-span-2">Rettungssanitäter</p>
          </div>
        </div>

        <div className="grid-cols-3 gap-x-4 gap-y-4 md:gap-y-2 hidden xl:grid">
          <p className="font-bold text-gray-500 text-sm">Juli 2022</p>
          <p className="col-span-2">Abitur</p>

          <p className="font-bold text-gray-500 text-sm">
            September 2022 ― Februar 2024
          </p>
          <p className="col-span-2">
            Fachinformatiker für Anwendungsentwicklung
            <br />
            <span className="text-gray-600 italic">
              Verkürzte Ausbildungsdauer
            </span>
          </p>

          <p className="font-bold text-gray-500 text-sm">
            September 2022 ― Februar 2024
          </p>
          <p className="col-span-2">Bachelorstudium Wirtschaftsinformatik</p>

          <p className="font-bold text-gray-500 text-sm">März 2024 ― Heute</p>
          <p className="col-span-2">Rettungssanitäter</p>
        </div>

        <button
          onClick={() => navigate("/contact")}
          className="py-4 px-8 border-4 hover:bg-black hover:text-white transition-all mt-12 border-black w-full"
        >
          Kontakt
        </button>
      </div>

    </motion.div>
  );
}

export default About;
