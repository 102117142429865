import { motion } from "framer-motion";

function Start() {
  return (
    <motion.div
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
      className="h-full"
      transition={{
        duration: 0.5,
        ease: [0.85, 0, 0.15, 1],
      }}
    >
      <img
        src="landing.webp"
        alt="Coding"
        className="object-cover h-full mx-6 pr-12 grayscale"
      />

    </motion.div>
  );
}

/*

  Old Gallery

      <div className="grid grid-cols-6 gap-6 h-full mx-6 md:mr-12 hover:object-scale-down">
        <img
          src="db_card.webp"
          className="bg-gray-400 object-cover w-full col-span-4 h-full"
          alt="Dominik Borchmann: Visitenkarte"
        />
        <img
          src="einsatzalarm.webp"
          className="bg-gray-400 object-cover w-full h-full col-span-2"
          alt="EinsatzAlarm: Mobile App"
        />
        <img
          src="db_card.webp"
          className="bg-gray-400 object-cover w-full h-full col-span-2"
          alt="Dominik Borchmann: Visitenkarte"
        />
        <img
          src="lenas_friseursalon.webp"
          className="bg-gray-400 object-cover w-full h-full col-span-4"
          alt="Dominik Borchmann: Visitenkarte"
        />
        <img
          src="tricon_energy.webp"
          className="bg-gray-400 object-cover w-full col-span-4 h-full"
          alt="TRICON Energy GmbH: Landing Page"
        />
        <img
          src="portale_greven.webp"
          className="bg-gray-400 object-cover w-full h-full col-span-2"
          alt="Restaurante Portale Greven: Landing Page"
        />
      </div> 

      */

export default Start;
