import { Outlet, useNavigate } from "react-router-dom";
import { Divide as Hamburger } from "hamburger-react";
import NavigationLink from "./ui/NavigationLink";
import { useState } from "react";

function UIWrapper() {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div
        className="w-3/4 bg-white border-black absolute h-full border-[12px] transition-all z-10"
        style={showMobileNav ? { left: "0" } : { left: "-75%" }}
      >
        <div className="flex justify-between flex-col h-full px-6 py-3">
          <div
            className="flex flex-col gap-6 mt-8"
            onClick={() => setShowMobileNav(false)}
          >
            <NavigationLink btnTo="" btnTitle="Start" />
            <NavigationLink btnTo="about" btnTitle="Über Mich" />
            <NavigationLink btnTo="projects" btnTitle="Projekte" />
            <NavigationLink btnTo="contact" btnTitle="Kontakt" />
          </div>
          <div className="flex justify-between text-gray-500">
            <NavigationLink btnTo="imprint" btnTitle="Impressum" />
            <NavigationLink btnTo="privacy" btnTitle="Datenschutz" />
          </div>
        </div>
      </div>
      <div className="h-screen w-screen border-black border-[12px] grid md:grid-cols-2 py-6 md:py-12">
        <div className="hidden md:flex flex-col justify-between h-full ml-12">
          <nav className="flex gap-6 text-gray-700">
            <NavigationLink btnTo="" btnTitle="Start" />
            <NavigationLink btnTo="about" btnTitle="Über Mich" />
            <NavigationLink btnTo="projects" btnTitle="Projekte" />
            <NavigationLink btnTo="contact" btnTitle="Kontakt" />
          </nav>
          <div>
            <h1 className="text-6xl font-serif">
              Tillian
              <br />
              Borchmann
            </h1>
            <h2 className="text-xl">Design & Code</h2>
            <p className="text-xs text-gray-400 mt-8">
              <span
                className="cursor-pointer"
                onClick={() => navigate("/imprint")}
              >
                Impressum
              </span>{" "}
              ―{" "}
              <span
                className="cursor-pointer"
                onClick={() => navigate("/privacy")}
              >
                Datenschutz
              </span>
            </p>
          </div>
        </div>
        <div className="mt-6">
          <div className="flex flex-row justify-between items-center mx-6 md:hidden max-h-min mb-12" >
            <div onClick={() => navigate('/')}>
              <h1 className="font-serif text-xl sm:text-3xl">
                Tillian Borchmann
              </h1>
              <p className="text-sm sm:text-lg">Design & Code</p>
            </div>
            <Hamburger
              size={24}
              onToggle={() => setShowMobileNav((prev) => !prev)}
            />
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default UIWrapper;
